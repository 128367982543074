<template>
  <cau-hinh-cong-thanh-toan />
</template>
<script>
import CauHinhCongThanhToan from '@/modules/danh-muc/components/pages/danh-muc/tien-ich/CauHinhCongThanhToan.vue'

export default {
  components: {
    CauHinhCongThanhToan,
  },
}
</script>
